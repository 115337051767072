import '../css/GuestSelectPicture.css';

function Frame1SelectPic(props) {

    const checkIsFinished = () => {
        if (props.imageSrc1 !== null
            && props.imageSrc2 !== null
            && props.imageSrc3 !== null
            && props.imageSrc4 !== null) {

            props.setIsFinished(true);
            return;
        }
    }

    checkIsFinished();

    return (
        <div style={{position: "relative"}}>
            <div>
                <svg width="158" height="458" viewBox="0 0 158 458" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_577_1923)">
                        <rect x="4" width="150" height="450" rx="10" fill="white" fill-opacity="0.9" shape-rendering="crispEdges" />
                    </g>
                    <rect x="18" y="16" width="122" height="80" rx="6" fill="#333333" />
                    <rect x="18" y="192" width="122" height="80" rx="6" fill="#333333" />
                    <rect x="18" y="104" width="122" height="80" rx="6" fill="#333333" />
                    <rect x="18" y="280" width="122" height="80" rx="6" fill="#333333" />
                    <path d="M86.5 49.25H83.9012L82.6234 47.3338C82.555 47.2311 82.4623 47.147 82.3535 47.0888C82.2448 47.0306 82.1233 47.0001 82 47H76C75.8767 47.0001 75.7552 47.0306 75.6465 47.0888C75.5377 47.147 75.445 47.2311 75.3766 47.3338L74.0978 49.25H71.5C70.9033 49.25 70.331 49.4871 69.909 49.909C69.4871 50.331 69.25 50.9033 69.25 51.5V62C69.25 62.5967 69.4871 63.169 69.909 63.591C70.331 64.0129 70.9033 64.25 71.5 64.25H86.5C87.0967 64.25 87.669 64.0129 88.091 63.591C88.5129 63.169 88.75 62.5967 88.75 62V51.5C88.75 50.9033 88.5129 50.331 88.091 49.909C87.669 49.4871 87.0967 49.25 86.5 49.25ZM82.375 56.375C82.375 57.0425 82.1771 57.695 81.8062 58.25C81.4354 58.8051 80.9083 59.2376 80.2916 59.4931C79.6749 59.7485 78.9963 59.8154 78.3416 59.6852C77.6869 59.5549 77.0855 59.2335 76.6135 58.7615C76.1415 58.2895 75.8201 57.6881 75.6898 57.0334C75.5596 56.3787 75.6265 55.7001 75.8819 55.0834C76.1374 54.4667 76.5699 53.9396 77.125 53.5688C77.68 53.1979 78.3325 53 79 53C79.8951 53 80.7535 53.3556 81.3865 53.9885C82.0194 54.6214 82.375 55.4799 82.375 56.375Z" fill="white" fill-opacity="0.5" />
                    <path d="M86.5 137.25H83.9012L82.6234 135.334C82.555 135.231 82.4623 135.147 82.3535 135.089C82.2448 135.031 82.1233 135 82 135H76C75.8767 135 75.7552 135.031 75.6465 135.089C75.5377 135.147 75.445 135.231 75.3766 135.334L74.0978 137.25H71.5C70.9033 137.25 70.331 137.487 69.909 137.909C69.4871 138.331 69.25 138.903 69.25 139.5V150C69.25 150.597 69.4871 151.169 69.909 151.591C70.331 152.013 70.9033 152.25 71.5 152.25H86.5C87.0967 152.25 87.669 152.013 88.091 151.591C88.5129 151.169 88.75 150.597 88.75 150V139.5C88.75 138.903 88.5129 138.331 88.091 137.909C87.669 137.487 87.0967 137.25 86.5 137.25ZM82.375 144.375C82.375 145.043 82.1771 145.695 81.8062 146.25C81.4354 146.805 80.9083 147.238 80.2916 147.493C79.6749 147.749 78.9963 147.815 78.3416 147.685C77.6869 147.555 77.0855 147.233 76.6135 146.761C76.1415 146.289 75.8201 145.688 75.6898 145.033C75.5596 144.379 75.6265 143.7 75.8819 143.083C76.1374 142.467 76.5699 141.94 77.125 141.569C77.68 141.198 78.3325 141 79 141C79.8951 141 80.7535 141.356 81.3865 141.989C82.0194 142.621 82.375 143.48 82.375 144.375Z" fill="white" fill-opacity="0.5" />
                    <path d="M86.5 225.25H83.9012L82.6234 223.334C82.555 223.231 82.4623 223.147 82.3535 223.089C82.2448 223.031 82.1233 223 82 223H76C75.8767 223 75.7552 223.031 75.6465 223.089C75.5377 223.147 75.445 223.231 75.3766 223.334L74.0978 225.25H71.5C70.9033 225.25 70.331 225.487 69.909 225.909C69.4871 226.331 69.25 226.903 69.25 227.5V238C69.25 238.597 69.4871 239.169 69.909 239.591C70.331 240.013 70.9033 240.25 71.5 240.25H86.5C87.0967 240.25 87.669 240.013 88.091 239.591C88.5129 239.169 88.75 238.597 88.75 238V227.5C88.75 226.903 88.5129 226.331 88.091 225.909C87.669 225.487 87.0967 225.25 86.5 225.25ZM82.375 232.375C82.375 233.043 82.1771 233.695 81.8062 234.25C81.4354 234.805 80.9083 235.238 80.2916 235.493C79.6749 235.749 78.9963 235.815 78.3416 235.685C77.6869 235.555 77.0855 235.233 76.6135 234.761C76.1415 234.289 75.8201 233.688 75.6898 233.033C75.5596 232.379 75.6265 231.7 75.8819 231.083C76.1374 230.467 76.5699 229.94 77.125 229.569C77.68 229.198 78.3325 229 79 229C79.8951 229 80.7535 229.356 81.3865 229.989C82.0194 230.621 82.375 231.48 82.375 232.375Z" fill="white" fill-opacity="0.5" />
                    <path d="M86.5 313.25H83.9012L82.6234 311.334C82.555 311.231 82.4623 311.147 82.3535 311.089C82.2448 311.031 82.1233 311 82 311H76C75.8767 311 75.7552 311.031 75.6465 311.089C75.5377 311.147 75.445 311.231 75.3766 311.334L74.0978 313.25H71.5C70.9033 313.25 70.331 313.487 69.909 313.909C69.4871 314.331 69.25 314.903 69.25 315.5V326C69.25 326.597 69.4871 327.169 69.909 327.591C70.331 328.013 70.9033 328.25 71.5 328.25H86.5C87.0967 328.25 87.669 328.013 88.091 327.591C88.5129 327.169 88.75 326.597 88.75 326V315.5C88.75 314.903 88.5129 314.331 88.091 313.909C87.669 313.487 87.0967 313.25 86.5 313.25ZM82.375 320.375C82.375 321.043 82.1771 321.695 81.8062 322.25C81.4354 322.805 80.9083 323.238 80.2916 323.493C79.6749 323.749 78.9963 323.815 78.3416 323.685C77.6869 323.555 77.0855 323.233 76.6135 322.761C76.1415 322.289 75.8201 321.688 75.6898 321.033C75.5596 320.379 75.6265 319.7 75.8819 319.083C76.1374 318.467 76.5699 317.94 77.125 317.569C77.68 317.198 78.3325 317 79 317C79.8951 317 80.7535 317.356 81.3865 317.989C82.0194 318.621 82.375 319.48 82.375 320.375Z" fill="white" fill-opacity="0.5" />
                    <defs>
                        <filter id="filter0_d_577_1923" x="0" y="0" width="158" height="458" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_1923" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_1923" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <img width="122px" height="80px" src={props.imageSrc1} className='guestSelectPictureFrame1Image1Img'></img>
            <div className='guestSelectPictureFrame1Image1Div'>
                <label htmlFor="frame1Image1" className='guestSelectPictureFrame1Label'></label>
            </div>
            <input
                accept="image/*"
                type="file"
                onChange={(e) => props.onUpload(e, 1)}
                id="frame1Image1"
                style={{ display: "none" }}></input>
            <img width="122px" height="80px" src={props.imageSrc2} className='guestSelectPictureFrame1Image2Img'></img>
            <div className='guestSelectPictureFrame1Image2Div'>
                <label htmlFor="frame1Image2" className='guestSelectPictureFrame1Label'></label>
            </div>
            <input
                accept="image/*"
                type="file"
                onChange={(e) => props.onUpload(e, 2)}
                id="frame1Image2"
                style={{ display: "none" }}></input>
            <img width="122px" height="80px" src={props.imageSrc3} className='guestSelectPictureFrame1Image3Img'></img>
            <div className='guestSelectPictureFrame1Image3Div'>
                <label htmlFor="frame1Image3" className='guestSelectPictureFrame1Label'></label>
            </div>
            <input
                accept="image/*"
                type="file"
                onChange={(e) => props.onUpload(e, 3)}
                id="frame1Image3"
                style={{ display: "none" }}></input>
            <img width="122px" height="80px" src={props.imageSrc4} className='guestSelectPictureFrame1Image4Img'></img>
            <div className='guestSelectPictureFrame1Image4Div'>
                <label htmlFor="frame1Image4" className='guestSelectPictureFrame1Label'></label>
            </div>
            <input
                accept="image/*"
                type="file"
                onChange={(e) => props.onUpload(e, 4)}
                id="frame1Image4"
                style={{ display: "none" }}></input>
        </div>
    );
}

export default Frame1SelectPic;