import '../css/GuestSubmit.css';
import Frame1 from '../icons/frame1.js';
import Frame2 from '../icons/frame2.js';
import Frame3 from '../icons/frame3.js';
import { useLocation, useNavigate } from 'react-router';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';
import FrameColorDesign from '../constants/FrameColorDesign.js';
import html2canvas from 'html2canvas';

const Text = {
    submission: "연말네컷 완성!\n카카오톡으로 사진을 공유해봐요."
}

function GuestSubmit() {

    const location = useLocation();
    const frameType = location.state.frameType;
    const image1 = location.state.image1;
    const image2 = location.state.image2;
    const image3 = location.state.image3;
    const image4 = location.state.image4;
    const frameDesign = location.state.frameDesign;

    const navigate = useNavigate();

    const [imageSrc1, setImageSrc1] = useState(null);
    const [imageSrc2, setImageSrc2] = useState(null);
    const [imageSrc3, setImageSrc3] = useState(null);
    const [imageSrc4, setImageSrc4] = useState(null);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://developers.kakao.com/sdk/js/kakao.js";
        script.async = true;
        document.body.appendChild(script);
        return () => document.body.removeChild(script);
    }, []);

    const frameEl = useRef(null); 

    // const downloadImage = async () => {
    //     const dataUrl = await htmlToImage.toPng(frameEl.current);

    //     // download image
    //     const link = document.createElement('a');
    //     link.download = 'photo-for-you.png';
    //     link.href = dataUrl;
    //     link.click();
    // };



    const shareKakao = () => { // url이 id값에 따라 변경되기 때문에 route를 인자값으로 받아줌
        if (window.Kakao) {
            const kakao = window.Kakao;
            if (!kakao.isInitialized()) {
                kakao.init(process.env.REACT_APP_SHARE_KAKAO_LINK_KEY); // 카카오에서 제공받은 javascript key를 넣어줌 -> .env파일에서 호출시킴
            }
            
            kakao.Link.sendDefault({
            objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
            content: {
                title: "연말네컷", // 인자값으로 받은 title
                description: "친구에게 한해의 네컷을 선물하세요.", // 인자값으로 받은 title
                imageUrl: "https://private-user-images.githubusercontent.com/78486898/307836060-90a2e6ef-0f0a-4869-ab19-8e4caea5bdb1.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTUiLCJleHAiOjE3MDg5NTk1OTQsIm5iZiI6MTcwODk1OTI5NCwicGF0aCI6Ii83ODQ4Njg5OC8zMDc4MzYwNjAtOTBhMmU2ZWYtMGYwYS00ODY5LWFiMTktOGU0Y2FlYTViZGIxLnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFWQ09EWUxTQTUzUFFLNFpBJTJGMjAyNDAyMjYlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjQwMjI2VDE0NTQ1NFomWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPWUzZTU1Yjg5NjAxNjEyMGVlNTNlOGM2ODFmNDQ1MmEzYWYzYTQ4MzQ4NjkxOGIyYjAxNWNjZjAzMGNiOTVlY2UmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.YafaQYFYdOxppStl782kuebcncssc-VNSZV1sa5O9H0",
                link: {
                    mobileWebUrl: "https://photo4yougo.com/", // 인자값으로 받은 route(uri 형태)
                    webUrl: "https://photo4yougo.com/"
                }
            },
            buttons: [
                {
                    title: "연말네컷 만들러 가기",
                    link: {
                        mobileWebUrl: "https://photo4yougo.com/",
                        webUrl: "https://photo4yougo.com/"
                    }
                }
            ]
            });
        }
    };

    const newPhoto = () => {
        navigate({
            pathname: '/guestselectframe',
        })
    };

    const goToDownload = () => {
        if (navigator.userAgent.match(/iPhone|iPad/i)) { // 아이폰 접속 경우
            window.open("https://apps.apple.com/kr/app/timebomb-%ED%83%80%EC%9E%84%EB%B0%A4/id1658754766");
            return;
        }
        else { // 안드로이드 접속 경우
            window.open("https://play.google.com/store/apps/details?id=org.pipeline.timebomb");
            return;
        }
    }

    const image1Load = async () => {
        const reader = new FileReader();
        reader.readAsDataURL(image1);
        return new Promise((resolve) => {
            reader.onload = () => {
                const src = reader.result;
                setImageSrc1(src);
                resolve(src);
            }
        });
    };

    const image2Load = async () => {
        const reader = new FileReader();
        reader.readAsDataURL(image2);
        return new Promise((resolve) => {
            reader.onload = () => {
                setImageSrc2(reader.result);
                resolve(imageSrc2);
            }
        });
    };

    const image3Load = async () => {
        const reader = new FileReader();
        reader.readAsDataURL(image3);
        return new Promise((resolve) => {
            reader.onload = () => {
                setImageSrc3(reader.result);
                resolve(imageSrc3);
            }
        });
    };

    const image4Load = async () => {
        const reader = new FileReader();
        reader.readAsDataURL(image4);
        return new Promise((resolve) => {
            reader.onload = () => {
                setImageSrc4(reader.result);
                resolve(imageSrc4);
            }
        });
    };


    useEffect(() => {
        async function loadImage() {
            if (image1 != null) {
                await image1Load();
            }
    
            if (image2 != null) {
                await image2Load();
            }
    
            if (image3 != null) {
                await image3Load();
            }
    
            if (image4 != null) {
                await image4Load();
            }
        }
        // console.log(imageSrc1);
        loadImage();

    }, [imageSrc1, imageSrc2, imageSrc3, imageSrc4])

    return (
        <div className='guestSubmitPageWrapper'>
            <div className='adsImageWrapper' onClick={goToDownload}> 
                <img className='AdsImg' src={"/assets/timebomb_ad.png"} width="100%"></img>
            </div>
            <div className='guestSubmitPage'
                style={{ backgroundColor: "#FFE8E3" }}>
                <div className='submissionText'
                    style={{ color: "#000000" }}>
                    {Text.submission}
                </div>
                <div id='frameEl' ref={frameEl}>
                {frameType === 1
                    ? <Frame1
                        width={"120"}
                        height={"368"}
                        fill={frameDesign > 100 ? "white" : FrameColorDesign[frameDesign - 1].color}
                        isFill={true}
                        from={"submit"}
                        image1={imageSrc1}
                        image2={imageSrc2}
                        image3={imageSrc3}
                        image4={imageSrc4}></Frame1>
                    : frameType === 2
                        ? <Frame2
                            width={"260"}
                            height={"384"}
                            fill={FrameColorDesign[frameDesign - 1].color}
                            from={"submit"}
                            isFill={true}
                            image1={imageSrc1}
                            image2={imageSrc2}
                            image3={imageSrc3}
                            image4={imageSrc4}></Frame2>
                        : <Frame3
                            width={"250"}
                            height={"298"}
                            fill={FrameColorDesign[frameDesign - 1].color}
                            isFill={true}
                            from={"submit"}
                            image1={imageSrc1}></Frame3>}
                </div>
                <button className='leavePicturesButton' onClick={() => shareKakao()}>
                    <div className='leavePicturesButtonText'>
                        카카오톡으로 연말네컷 공유하기
                    </div>
                </button>
                <button className='submitMakeMyPageButton'
                    style={{
                        borderColor: "transparent",
                        backgroundColor: "#F1929250"
                    }}
                    onClick={newPhoto}>
                    <div className='submitMakeMyPageButtonComponentWrapper'>
                        <div className='submitMakeMyPageButtonText'
                            style={{
                                color: "#333333",
                                WebkitTextStroke: '1px',
                                WebkitTextStrokeColor: "#333333"
                            }}>
                            새로운 연말네컷 만들기
                        </div>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default GuestSubmit;