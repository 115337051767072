import '../css/GuestSelectFrameDesign.css';
import '../css/GuestSelectFrame.css';
import { GridListTile, GridList } from '@material-ui/core';
import Frame1 from '../icons/frame1.js';
import Frame2 from '../icons/frame2.js';
import Frame3 from '../icons/frame3.js';
import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import LeftArrow from '../icons/left_arrow.js';
import RightArrow from '../icons/right_arrow.js';
import PicturesDouble from '../icons/pictures_double.js';
import Close from '../icons/close';
import FrameColorDesign from '../constants/FrameColorDesign.js';

function GuestSelectFrameColor(props) {
    return (
        <div className='frameDesignSelect'
            style={{ backgroundColor: "#FFE8E3" }}>
            <GridList cellHeight={100} cols={3} spacing={20} className='guestSelectFrameGridList' style={{ margin: '0' }}>
                {FrameColorDesign.map(tile => (
                    <GridListTile key={tile.id} style={{ padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='frameColorBoxWrapper' onClick={(e) => { props.setDesign(tile.id, e) }}>
                            <div className='frameColor'
                                style={{
                                    backgroundColor: tile.color,
                                    borderTop: props.frameDesign === tile.id ? `2px ${"#F19292"} solid` : "2px transparent solid",
                                    borderLeft: props.frameDesign === tile.id ? `2px ${"#F19292"} solid` : "2px transparent solid",
                                    borderRight: props.frameDesign === tile.id ? `2px ${"#F19292"} solid` : "2px transparent solid",
                                }}>
                            </div>
                            <div className='colorName'
                                style={{ backgroundColor: props.frameDesign === tile.id ? "#F19292" : "#FAFAFA" }}>
                                <div className='colorNameText' style={{ color: props.frameDesign === tile.id ? "#FFFFFF" : "#191919" }}>
                                    {tile.name}
                                </div>
                            </div>
                        </div>
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}

function GuestSelectFrameDesign() {

    const location = useLocation();
    const frameType = location.state.frameType;
    const image1 = location.state.image1;
    const image2 = location.state.image2;
    const image3 = location.state.image3;
    const image4 = location.state.image4;

    const navigator = useNavigate();

    const [frameDesign, setFrameDesign] = useState();
    const [isDesignSelected, setIsDesignSelected] = useState(false);

    const [imageSrc1, setImageSrc1] = useState(null);
    const [imageSrc2, setImageSrc2] = useState(null);
    const [imageSrc3, setImageSrc3] = useState(null);
    const [imageSrc4, setImageSrc4] = useState(null);

    useEffect(() => {
        const image1Load = async () => {
            const reader = new FileReader();
            reader.readAsDataURL(image1);
            return new Promise((resolve) => {
                reader.onload = () => {
                    setImageSrc1(reader.result);
                    resolve();
                }
            });
        };

        const image2Load = async () => {
            const reader = new FileReader();
            reader.readAsDataURL(image2);
            return new Promise((resolve) => {
                reader.onload = () => {
                    setImageSrc2(reader.result);
                    resolve();
                }
            });
        };

        const image3Load = async () => {
            const reader = new FileReader();
            reader.readAsDataURL(image3);
            return new Promise((resolve) => {
                reader.onload = () => {
                    setImageSrc3(reader.result);
                    resolve();
                }
            });
        };

        const image4Load = async () => {
            const reader = new FileReader();
            reader.readAsDataURL(image4);
            return new Promise((resolve) => {
                reader.onload = () => {
                    setImageSrc4(reader.result);
                    resolve();
                }
            });
        };



        if (image1 != null) {
            image1Load();
        }

        if (image2 != null) {
            image2Load();
        }

        if (image3 != null) {
            image3Load();
        }

        if (image4 != null) {
            image4Load();
        }

    }, [])

    const setDesign = (id, e) => {
        setFrameDesign(id);
        setIsDesignSelected(true);
    }

    const goBack = () => {
        navigator(-1);
    }

    const goNext = () => {
        if (!isDesignSelected) {
            return;
        }

        navigator({
            pathname: '/guestsubmit/',
        },
            {
                state: {
                    frameType: frameType,
                    frameDesign: frameDesign,
                    image1: image1,
                    image2: image2,
                    image3: image3,
                    image4: image4,
                }
            });
    }

    return (
        <div className='guestSelectFrameDesignPageWrapper'>
            <div className='guestSelectFrameDesignPage'
                style={{ backgroundColor:"#FFE8E3" }}>
                <div className='guestSelectFrameDesignHeader'
                    style={{ backgroundColor: "#F19292" }}>
                    <div className='guestSelectFrameDesignTextWrapper'>
                        <div className='guestSelectFrameDesignText'
                            style={{ color: "#FFFFFF" }}>
                            디자인을 선택해 주세요.
                        </div>
                    </div>
                    <div className='guestSelectFrameDesignVerticalSeparateLine'></div>
                    <div className='guestSettingProgressWrapper'>
                        <div className='guestSettingProgress'
                            style={{ color: "#FFFFFF" }}>
                            3/3
                        </div>
                    </div>
                    <div className='guestSelectFrameDesignHorizontalSeparateLine'></div>
                </div>
                <div className='guestSelectFrameDesignBody'>
                    <div className='frameDesignPreviewWrapper'>
                        {frameType === 1
                            ? <Frame1 width={"170"}
                                height={"256"}
                                fill={(frameDesign != null) ? FrameColorDesign[frameDesign - 1].color : "white"}
                                isFill={true}
                                image1={imageSrc1}
                                image2={imageSrc2}
                                image3={imageSrc3}
                                image4={imageSrc4}></Frame1>
                            : frameType === 2
                                ? <Frame2 width={"170"}
                                    height={"232"}
                                    fill={(frameDesign != null) ? FrameColorDesign[frameDesign - 1].color : "white"}
                                    isFill={true}
                                    image1={imageSrc1}
                                    image2={imageSrc2}
                                    image3={imageSrc3}
                                    image4={imageSrc4}></Frame2>
                                : <Frame3 width={"170"}
                                    height={"188"}
                                    fill={(frameDesign != null) ? FrameColorDesign[frameDesign - 1].color : "white"}
                                    isFill={true}
                                    image1={imageSrc1}></Frame3>}
                    </div>
                    <div className='guestSelectFrameDesignHorizontalSeparateLine'
                        style={{ borderColor: "#F19292" }}></div>
                    <div>
                        <GuestSelectFrameColor
                            setDesign={setDesign}
                            frameDesign={frameDesign}></GuestSelectFrameColor>
                    </div>
                </div>
                <div className='guestSelectFrameDesignFooter'>
                    <div className='guestSelectFrameDesignHorizontalSeparateLine'></div>
                    <div className='guestSelectFrameDesignButtonsWrapper'>
                        <div className='guestSelectFrameDesignBackWrapper'
                            onClick={goBack}
                            style={{ backgroundColor: "#F19292" }}>
                            <div className='guestSelectFrameDesignBackLeftArrowIcon'>
                                <LeftArrow
                                    fill={"#FFFFFF"}></LeftArrow>
                            </div>
                            <div className='guestSelectFrameDesignBackText'
                                style={{ color: "#FFFFFF" }}>
                                뒤로
                            </div>
                        </div>
                        <div className='guestSelectFrameDesignVerticalSeparateLine'></div>
                        <div className='guestSelectFrameDesignNextWrapper'
                            style={{ backgroundColor: isDesignSelected ? "#F19292" : '#808080' }}
                            onClick={isDesignSelected ? goNext : null}>
                            <div className='guestSelectFrameDesignNextText'
                                style={{ color: "#FFFFFF" }}>
                                완성하기
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuestSelectFrameDesign;