import '../css/Home.css'
import PicturesDouble from '../icons/pictures_double';
import { ReactComponent as MainLogo } from '../icons/main_logo.svg';
import ManualPopup from './ManualPopup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Text = {
    description: "연말네컷\n사용방법",
    title: "연말네컷",
    titleDescription: "친구에게 한해의 네 컷을 선물하세요.",
}

function Home() {
    const [isShowManual, setIsShowManual] = useState(false);

    const showManual = () => {
        setIsShowManual(!isShowManual);
    }

    const openPersonalPolicy = () => {
        window.open("https://photofouryou.notion.site/a09efbf3dcab41f595d0d07aac1f272c")
    }

    const openTermsOfUse = () => {
        window.open("https://photofouryou.notion.site/4464508b8f434fe990cf91a7e3d20b03")
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://developers.kakao.com/sdk/js/kakao.js";
        script.async = true;
        document.body.appendChild(script);
        return () => document.body.removeChild(script);
    }, []);

    const shareKakao = () => { // url이 id값에 따라 변경되기 때문에 route를 인자값으로 받아줌
        if (window.Kakao) {
            const kakao = window.Kakao;
            if (!kakao.isInitialized()) {
                kakao.init(process.env.REACT_APP_SHARE_KAKAO_LINK_KEY); // 카카오에서 제공받은 javascript key를 넣어줌 -> .env파일에서 호출시킴
            }
            
            kakao.Link.sendDefault({
            objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
            content: {
                title: "연말네컷", // 인자값으로 받은 title
                description: "친구에게 한해의 네컷을 선물하세요.", // 인자값으로 받은 title
                imageUrl: "https://private-user-images.githubusercontent.com/78486898/307836060-90a2e6ef-0f0a-4869-ab19-8e4caea5bdb1.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTUiLCJleHAiOjE3MDg5NTk1OTQsIm5iZiI6MTcwODk1OTI5NCwicGF0aCI6Ii83ODQ4Njg5OC8zMDc4MzYwNjAtOTBhMmU2ZWYtMGYwYS00ODY5LWFiMTktOGU0Y2FlYTViZGIxLnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFWQ09EWUxTQTUzUFFLNFpBJTJGMjAyNDAyMjYlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjQwMjI2VDE0NTQ1NFomWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPWUzZTU1Yjg5NjAxNjEyMGVlNTNlOGM2ODFmNDQ1MmEzYWYzYTQ4MzQ4NjkxOGIyYjAxNWNjZjAzMGNiOTVlY2UmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.YafaQYFYdOxppStl782kuebcncssc-VNSZV1sa5O9H0",
                link: {
                    mobileWebUrl: "https://photo4yougo.com/", // 인자값으로 받은 route(uri 형태)
                    webUrl: "https://photo4yougo.com/"
                }
            },
            buttons: [
                {
                    title: "연말네컷 만들러 가기",
                    link: {
                        mobileWebUrl: "https://photo4yougo.com/",
                        webUrl: "https://photo4yougo.com/"
                    }
                }
            ]
            });
        }
    };

    // const copyLink = async (text) => {
    //     try {
    //         await navigator.clipboard.writeText(text);
    //         alert('내 연말네컷 링크를 복사했습니다!\n친구들에게 링크를 공유해보세요.');
    //     } catch (e) {
    //         alert('연말네컷 링크를 복사에 실패했습니다.\n다시 시도해주세요.');
    //     }
    // };

    const navigator = useNavigate();

    const goNext = () => {
        navigator({
            pathname: "/guestselectframe/",
        },);
    }

    return (
        <div className='homePageWrapper'>
            <div className='homePage'>
                <div className='homeBackground'>
                    <div className='descriptionWrapper'>
                        <div className='descriptionTextWrapper'>
                            <div className='descriptionText'>
                                {Text.description}
                            </div>
                        </div>
                        <div className='descriptionIconWrapper' onClick={showManual}>
                            <span className='descriptionIconBackground'>
                                <PicturesDouble className='descriptionIcon' fill={"#FFFFFF"}></PicturesDouble>
                            </span>
                        </div>
                    </div>
                    <div className='homeBox'></div>
                    <div className="mainLogoWrapper">
                        <MainLogo className='mainLogo'></MainLogo>
                    </div>
                    <div className='titleWrapper'>
                        <div className='titleText'>
                            {Text.title}
                        </div>
                        <div className='titleDescriptionText'>
                            {Text.titleDescription}
                        </div>
                    </div>
                    <div className='homeButtonWrapper'>
                    <div className='makePictureButtonWrapper'>
                        <button className='makePictureButtonMain' onClick={goNext}>
                            <div className='makePictureButtonText'>
                                연말네컷 만들러 가기
                            </div>
                        </button>
                    </div>
                    <div className='homeLinkButtonWrapper'>
                        <button className='homeLinkButtonMain' onClick={shareKakao}>
                            <div className='homeLinkButtonText'>
                                친구들에게 연말네컷 소문내기
                            </div>
                        </button>
                    </div>
                    </div>
                    <div className='policyWrapper'>
                        <div className='policyUnderlineWrapper' onClick={openPersonalPolicy}>
                            <div className='homePolicyText'>
                                개인정보 처리방침
                            </div>
                            <div className='homePersonalPolicyUnderline'></div>
                        </div>
                        <div className='policyUnderlineWrapper' onClick={openTermsOfUse}>
                            <div className='homePolicyText'>
                                이용약관
                            </div>
                            <div className='homeTermsUnderline'></div>
                        </div>
                    </div>
                </div>
            </div>
            {isShowManual ? <ManualPopup isShowManual={isShowManual} setIsShowManual={setIsShowManual}></ManualPopup> : null}
        </div>
    )
}

export default Home;