import '../css/GuestSelectFrameDesign.css';
import '../css/GuestSubmit.css';

function Frame3({ width, height, fill, isFill, image1, from }) {

    return (
        <div style={{ position: "relative" }}>
            <div>
                <svg width={width} height={height} viewBox="0 0 170 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_299_2295)">
                        <rect x="15" y="10" width="140" height="168" rx="6" fill={fill} fill-opacity="0.9" shape-rendering="crispEdges" />
                    </g>
                    {!isFill ? <rect x="25" y="20" width="120" height="120" rx="4" fill="#333333" /> : null}
                    {/* <rect x="5" y="33.9412" width="48" height="12" rx="1" transform="rotate(-45 5 33.9412)" fill="white" fill-opacity="0.7" /> */}
                    <defs>
                        <filter id="filter0_d_299_2295" x="11" y="10" width="148" height="176" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_299_2295" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_299_2295" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            {isFill
                ? from === "submit"
                    ? <img width="176px" height="176px" src={image1} className='guestSubmitFrame3Image1Img'></img>
                    : <img width="120px" height="120px" src={image1} className='guestSelectFrame3Image1Img'></img>
                : null
            }
            <div style={{ position: 'absolute', top: '50%', left: '52%', transform: 'translate(-50%, -50%)' }}>
                <svg width={width} height={height} viewBox="0 0 170 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="33.9412" width="48" height="12" rx="1" transform="rotate(-45 5 33.9412)" fill="white" fill-opacity="0.7" />
                </svg>
            </div>
        </div >
    );
}

export default Frame3;