const FrameColorDesign = [
    {
        id: 1,
        color: "#BA5742",
        name: "레드와인"
    },
    {
        id: 2,
        color: "#FFFFFF",
        name: "화이트"
    },
    {
        id: 3,
        color: "#D2E4F9",
        name: "하늘"
    },
    {
        id: 4,
        color: "#72704E",
        name: "올리브"
    },
    {
        id: 5,
        color: "#A092B4",
        name: "포도"
    },
    {
        id: 6,
        color: "#E9CFA7",
        name: "병아리"
    }
]

export default FrameColorDesign;