import '../css/Manual.css';
import Close from '../icons/close';
import React, { useEffect } from "react";
import { ReactComponent as ManualImage } from '../icons/manual_image.svg';


const Text = {
    title: "연말네컷 사용방법",
    overviewTitle: "연말네컷이란?",
    overviewText: "연말네컷에서는 우리의 소중한 순간들을\n네컷 프레임에 넣어 저장하고 공유할 수 있답니다.\n하루하루를 모아 2024년도 다채롭게 채워보세요!",
    description: "연말네컷 이용방법",
    description1: "기록하고 싶은 추억과\n어울리는 프레임을 선택해요.",
    description2: "사진을 마음껏 배치하여 완성해요.",
    description3: "추억을 함께한 친구 혹은 나에게\n카카오톡으로 공유하여 연말네컷을 저장해요.",
    guideTitle: "친구에게 연말네컷을 남겨보세요!",
    guide1: "다양한 프레임을 사용해서\n함께한 여행/데이트/졸업식 등을\n기록할 수 있어요.",
    guide2: "고마운 이들에게 연말네컷과 가벼운 편지로\n 마음을 전해보는 것은 어떨까요?",
    goToNotion: "연말네컷 더 알아보기",
    goToInstagram: "@photo4.you_official"
}

function Manual() {
    const openNotion = () => {
        window.open("https://photofouryou.notion.site/2023-23faf9d33d7c4042b06555b6be6cf471?pvs=4")
    }

    const openInstagram = () => {
        window.open("https://photofouryou.notion.site/2023-23faf9d33d7c4042b06555b6be6cf471?pvs=4")
    }

    return (
        <div className='manualWrapper'>
            <div className='manualTitleWrapper'>
                {Text.title}
            </div>
            <div className='manualScrollWrapper'>
                <div className='manualBoxWrapper'>
                    <div className='manualBoxTitleWrapper'>
                        {Text.overviewTitle}
                    </div>
                    <div className='manualDescriptionTextWrapper'>
                        {Text.overviewText}
                    </div>
                </div>
                <div className='manualBoxWrapper'>
                    <div className='manualBoxTitleWrapper'>
                        {Text.description}
                    </div>
                    <div className='descriptionNumberWrapper'>
                        <span className='descriptionNumberBackground'>
                            <div className='descriptionNumberText'>
                                1
                            </div>
                        </span>
                    </div>
                    <div className='manualDescriptionTextWrapper'>
                        {Text.description1}
                    </div>
                    <div className='descriptionNumberWrapper' style={{marginTop: '20px'}}>
                        <span className='descriptionNumberBackground'>
                            <div className='descriptionNumberText'>
                                2
                            </div>
                        </span>
                    </div>
                    <div className='manualDescriptionTextWrapper'>
                        {Text.description2}
                    </div>
                    <div className='descriptionNumberWrapper'  style={{marginTop: '20px'}}>
                        <span className='descriptionNumberBackground'>
                            <div className='descriptionNumberText'>
                                3
                            </div>
                        </span>
                    </div>
                    <div className='manualDescriptionTextWrapper'>
                        {Text.description3}
                    </div>
                </div>
                <div className='manualBoxWrapper'>
                    <div className='manualBoxTitleWrapper'>
                        {Text.guideTitle}
                    </div>
                    <div className='manualImageWrapper'>
                        <ManualImage></ManualImage>
                    </div>
                    <div className='manualDescriptionTextWrapper'>
                        {Text.guide1}
                    </div>
                    <div className='manualDescriptionTextWrapper'>
                        {Text.guide2}
                    </div>
                </div>
                <div className='buttonWrapper'>
                    <button className='goToNotionButton' onClick={openNotion}>
                        <div className='goToNotionButtonText'>
                            {Text.goToNotion}
                        </div>
                    </button>
                </div>
                <div className='buttonWrapper'>
                    <button className='goToInstagramButton' onClick={openInstagram}>
                        <div className='goToInstagramButtonText'>
                            {Text.goToInstagram}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

function ManualPopup(props) {
    const showManual = () => {
        props.setIsShowManual(!props.isShowManual);
    };

    useEffect(() => {
        document.body.style.cssText = `
            position: fixed; 
            top: -${window.scrollY}px;
            overflow-y: scroll;
            width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY));
        };
    }, []);

    return (
        <div className='overlay'>
            <div className='openManualWrapper'>
                <div className='manualCloseWrapper' onClick={() => showManual()}>
                    <span className='manualCloseBackground'>
                        <Close className='manualCloseIcon' stroke={"#F19292"}></Close>
                    </span>
                </div>
                <Manual></Manual>
            </div>
        </div>
    );
}

export default ManualPopup;