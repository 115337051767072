function LeftArrow({ fill }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86845 0.198869L0.214206 4.51989C-0.0714016 4.78505 -0.0714016 5.21495 0.214206 5.48011L4.86845 9.80113C5.15405 10.0663 5.61711 10.0663 5.90272 9.80113C6.18833 9.53597 6.18833 9.10606 5.90272 8.8409L2.49696 5.67898L13.2687 5.67898C13.6726 5.67898 14 5.37499 14 5C14 4.62501 13.6726 4.32102 13.2687 4.32102L2.49696 4.32102L5.90272 1.1591C6.18833 0.893937 6.18833 0.464028 5.90272 0.198869C5.61712 -0.0662898 5.15405 -0.0662898 4.86845 0.198869Z" fill={fill} />
            </svg>
        </div>
    )
}

export default LeftArrow;