import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import Home from './pages/Home';
import GuestSelectFrame from './pages/GuestSelectFrame';
import GuestSelectPicture from './pages/GuestSelectPicture';
import GuestSelectFrameDesign from './pages/GuestSelectFrameDesign';
import GuestSubmit from './pages/GuestSubmit';

function App() {
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    setScreenSize();
  });

  return (
    <div className="App">
      <div className="Page">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/guestselectframe/*" element={<GuestSelectFrame />} />
          <Route path="/guestselectpicture/*" element={<GuestSelectPicture/>} />
          <Route path="/guestselectframedesign/*" element={<GuestSelectFrameDesign/>} />
          <Route path="/guestsubmit" element={<GuestSubmit/>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
