function Close({ stroke }) {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L9 9" stroke={stroke} stroke-width="1.4" stroke-linecap="round" />
            <path d="M1 9L9 1" stroke={stroke} stroke-width="1.4" stroke-linecap="round" />
        </svg>
    );
}

export default Close;
