import { useEffect } from 'react';
import '../css/GuestSelectPicture.css';

function Frame3SelectPic(props) {


    const checkIsFinished = () => {
        if (props.imageSrc1 !== null) {
            props.setIsFinished(true);
            return;
        }
    }

    checkIsFinished();



    return (
        <div style={{ position: "relative" }}>
            <div>
                <svg width="308" height="368" viewBox="0 0 308 368" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_595_1763)">
                        <rect x="4" width="300" height="360" rx="10" fill="white" fill-opacity="0.9" shape-rendering="crispEdges" />
                    </g>
                    <rect x="19" y="20" width="270" height="270" rx="6" fill="#333333" />
                    <path d="M161.5 148.25H158.901L157.623 146.334C157.555 146.231 157.462 146.147 157.354 146.089C157.245 146.031 157.123 146 157 146H151C150.877 146 150.755 146.031 150.646 146.089C150.538 146.147 150.445 146.231 150.377 146.334L149.098 148.25H146.5C145.903 148.25 145.331 148.487 144.909 148.909C144.487 149.331 144.25 149.903 144.25 150.5V161C144.25 161.597 144.487 162.169 144.909 162.591C145.331 163.013 145.903 163.25 146.5 163.25H161.5C162.097 163.25 162.669 163.013 163.091 162.591C163.513 162.169 163.75 161.597 163.75 161V150.5C163.75 149.903 163.513 149.331 163.091 148.909C162.669 148.487 162.097 148.25 161.5 148.25ZM157.375 155.375C157.375 156.043 157.177 156.695 156.806 157.25C156.435 157.805 155.908 158.238 155.292 158.493C154.675 158.749 153.996 158.815 153.342 158.685C152.687 158.555 152.086 158.233 151.614 157.761C151.142 157.289 150.82 156.688 150.69 156.033C150.56 155.379 150.626 154.7 150.882 154.083C151.137 153.467 151.57 152.94 152.125 152.569C152.68 152.198 153.332 152 154 152C154.895 152 155.754 152.356 156.386 152.989C157.019 153.621 157.375 154.48 157.375 155.375Z" fill="white" fill-opacity="0.5" />
                    <defs>
                        <filter id="filter0_d_595_1763" x="0" y="0" width="308" height="368" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_595_1763" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_595_1763" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <img width="270px" height="270px" src={props.imageSrc1} className='guestSelectPictureFrame3Image1Img'></img>
            <div className='guestSelectPictureFrame3Image1Div'>
                <label htmlFor="frame3Image1" className='guestSelectPictureFrame3Label'></label>
            </div>
            <input
                accept="image/*"
                type="file"
                onChange={(e) => {
                    props.onUpload(e, 1);
                }}
                id="frame3Image1"
                style={{ display: "none" }}></input>
        </div>
    );
}

export default Frame3SelectPic;