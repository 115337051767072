import '../css/GuestSelectFrame.css';
import { GridListTile, GridList } from '@material-ui/core';
import Frame1 from '../icons/frame1.js';
import Frame2 from '../icons/frame2.js';
import Frame3 from '../icons/frame3.js';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import LeftArrow from '../icons/left_arrow.js';
import RightArrow from '../icons/right_arrow.js';

const data = [
    {
        type: 1,
        fileName: Frame1,
        title: "4컷 긴 프레임",
        width: "170",
        height: "256"
    },
    {
        type: 2,
        fileName: Frame2,
        title: "4컷 짧은 프레임",
        width: "170",
        height: "232"
    },
    {
        type: 3,
        fileName: Frame3,
        title: "폴라로이드",
        width: "170",
        height: "188"
    }
]

function GuestSelectFrame() {

    const navigator = useNavigate();

    const [isSelected, setIsSelected] = useState(false);
    const [selectFrameID, setSelectID] = useState();

    const selectFrame = (id, e) => {
        setIsSelected(true);
        setSelectID(id);
    }

    const goBack = () => {
        navigator(-1);
    }

    const goNext = () => {
        if (!isSelected) {
            return;
        }

        navigator({
            pathname: '/guestSelectPicture/'
        },
            {
                state: {
                    frameType: selectFrameID
                }
            });
    }


    return (
        <div className='guestSelectFramePageWrapper'>
            <div className='guestSelectFramePage'
                style={{ backgroundColor: "#FFE8E3" }}>
                <div className='guestSelectFrameHeader'
                    style={{ backgroundColor: "#F19292" }}>
                    <div className='guestSelectFrameTextWrapper'>
                        <div className='guestSelectFrameText'
                            style={{ color: "#FFFFFF" }}>
                            프레임을 선택해 주세요.
                        </div>
                    </div>
                    <div className='guestSelectFrameVerticalSeparateLine'></div>
                    <div className='guestSettingProgressWrapper'>
                        <div className='guestSettingProgress'
                            style={{ color: "#FFFFFF" }}>
                            1/3
                        </div>
                    </div>
                    <div className='guestSelectFrameHorizontalSeparateLine'></div>
                </div>
                <div className='guestSelectFrameBody'>
                    <div className='guestSelectFrameGridListWrapper'>
                        <GridList cellHeight={380} cols={1} className='guestSelectFrameGridList' style={{ margin: '0' }}>
                            {data.map(tile => (
                                <GridListTile key={tile.type} style={{ padding: '0' }}>
                                    <div className='guestSelectFrameGrid'
                                        onClick={(e) => { selectFrame(tile.type, e) }}
                                        style={{
                                            backgroundColor: isSelected && selectFrameID === tile.type
                                                ? "#F1929230"
                                                : '#FFE8E3'
                                        }}>
                                        <div className='frameGridExpand'>
                                            <div className='frameSelectWrapper'>
                                                <span className='frameSelect'
                                                    onClick={(e) => { selectFrame(tile.type, e) }}
                                                    style={{
                                                        backgroundColor: "#F1929210",
                                                        borderColor: "#F19292"
                                                    }}>
                                                    {isSelected && selectFrameID === tile.type
                                                        ? <span className='selectedFrame'
                                                            style={{ backgroundColor: "#F19292" }}></span>
                                                        : null}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='frameWrapper'>
                                            <div className='photoWallFrameWrapper'>
                                                <tile.fileName width={tile.width} height={tile.height} fill={"white"}></tile.fileName>
                                            </div>
                                            <div className='frameTitle'
                                                style={{ color: "#000000" }}>
                                                {tile.title}
                                            </div>
                                        </div>
                                        <div className='frameGridExpand'></div>
                                        <div className='guestSelectFrameHorizontalSeparateLine'
                                            style={{ borderColor: "#F19292" }}></div>
                                    </div>
                                </GridListTile>

                            ))}
                        </GridList>
                    </div>
                </div>
                <div className='guestSelectFrameFooter'>
                    <div className='guestSelectFrameHorizontalSeparateLine'></div>
                    <div className='guestSelectFrameButtonsWrapper'>
                        <div className='guestSelectFrameBackWrapper'
                            onClick={goBack}
                            style={{ backgroundColor: "#F19292" }}>
                            <div className='guestSelectFrameBackLeftArrowIcon'>
                                <LeftArrow fill={"#FFFFFF"}></LeftArrow>
                            </div>
                            <div className='guestSelectFrameBackText'
                                style={{ color: "#FFFFFF" }}>
                                뒤로
                            </div>
                        </div>
                        <div className='guestSelectFrameVerticalSeparateLine'></div>
                        <div className='guestSelectFrameNextWrapper'
                            style={{ backgroundColor: isSelected ? "#F19292" : '#808080' }}
                            onClick={isSelected ? goNext : null}>
                            <div className='guestSelectFrameNextText'
                                style={{ color: "#FFFFFF" }}>
                                다음
                            </div>
                            <div className='guestSelectFrameNextRightArrowIcon'>
                                <RightArrow fill={"#FFFFFF"}></RightArrow>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuestSelectFrame;