function PicturesDouble({ fill }) {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5.96924" y="1" width="8" height="24" rx="1" fill={fill} />
                <rect x="6.96924" y="2" width="6" height="4" rx="1" fill="#333333" />
                <rect x="6.96924" y="7" width="6" height="4" rx="1" fill="#333333" />
                <rect x="6.96924" y="12" width="6" height="4" rx="1" fill="#333333" />
                <rect x="6.96924" y="17" width="6" height="4" rx="1" fill="#333333" />
                <rect x="9.21143" y="0.373657" width="8" height="24" rx="1" transform="rotate(15 9.21143 0.373657)" fill={fill} />
                <rect x="9.91846" y="1.5984" width="6" height="4" rx="1" transform="rotate(15 9.91846 1.5984)" fill="#333333" />
                <rect x="8.62451" y="6.42802" width="6" height="4" rx="1" transform="rotate(15 8.62451 6.42802)" fill="#333333" />
                <rect x="7.33057" y="11.2577" width="6" height="4" rx="1" transform="rotate(15 7.33057 11.2577)" fill="#333333" />
                <rect x="6.03613" y="16.0873" width="6" height="4" rx="1" transform="rotate(15 6.03613 16.0873)" fill="#333333" />
            </svg>
        </div>
    );
}

export default PicturesDouble;
