function RightArrow({ fill }) {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.13155 0.198869L13.7858 4.51989C14.0714 4.78505 14.0714 5.21495 13.7858 5.48011L9.13155 9.80113C8.84595 10.0663 8.38289 10.0663 8.09728 9.80113C7.81167 9.53597 7.81167 9.10606 8.09728 8.8409L11.503 5.67898L0.731343 5.67898C0.327434 5.67898 6.22735e-08 5.37499 0 5C-6.22735e-08 4.62501 0.327433 4.32102 0.731343 4.32102L11.503 4.32102L8.09728 1.1591C7.81167 0.893937 7.81167 0.464028 8.09728 0.198869C8.38288 -0.0662898 8.84595 -0.0662898 9.13155 0.198869Z" fill={fill} />
            </svg>
        </div>
    );
}

export default RightArrow;
