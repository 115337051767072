import '../css/GuestSelectPicture.css';

function Frame2SelectPic(props) {

    const checkIsFinished = () => {
        if (props.imageSrc1 !== null
            && props.imageSrc2 !== null
            && props.imageSrc3 !== null
            && props.imageSrc4 !== null) {

            props.setIsFinished(true);
            return true;
        }
    }

    checkIsFinished();

    return (
        <div style={{ position: "relative" }}>
            <div>
                <svg width="308" height="458" viewBox="0 0 308 458" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_577_1937)">
                        <rect x="4" width="300" height="450" rx="10" fill="white" fill-opacity="0.9" shape-rendering="crispEdges" />
                    </g>
                    <rect x="19" y="260" width="130" height="170" rx="6" fill="#333333" />
                    <rect x="19" y="80" width="130" height="170" rx="6" fill="#333333" />
                    <rect x="159" y="260" width="130" height="170" rx="6" fill="#333333" />
                    <rect x="159" y="80" width="130" height="170" rx="6" fill="#333333" />
                    <path d="M91.5 158.25H88.9012L87.6234 156.334C87.555 156.231 87.4623 156.147 87.3535 156.089C87.2448 156.031 87.1233 156 87 156H81C80.8767 156 80.7552 156.031 80.6465 156.089C80.5377 156.147 80.445 156.231 80.3766 156.334L79.0978 158.25H76.5C75.9033 158.25 75.331 158.487 74.909 158.909C74.4871 159.331 74.25 159.903 74.25 160.5V171C74.25 171.597 74.4871 172.169 74.909 172.591C75.331 173.013 75.9033 173.25 76.5 173.25H91.5C92.0967 173.25 92.669 173.013 93.091 172.591C93.5129 172.169 93.75 171.597 93.75 171V160.5C93.75 159.903 93.5129 159.331 93.091 158.909C92.669 158.487 92.0967 158.25 91.5 158.25ZM87.375 165.375C87.375 166.043 87.1771 166.695 86.8062 167.25C86.4354 167.805 85.9083 168.238 85.2916 168.493C84.6749 168.749 83.9963 168.815 83.3416 168.685C82.6869 168.555 82.0855 168.233 81.6135 167.761C81.1415 167.289 80.8201 166.688 80.6898 166.033C80.5596 165.379 80.6265 164.7 80.8819 164.083C81.1374 163.467 81.5699 162.94 82.125 162.569C82.68 162.198 83.3325 162 84 162C84.8951 162 85.7535 162.356 86.3865 162.989C87.0194 163.621 87.375 164.48 87.375 165.375Z" fill="white" fill-opacity="0.5" />
                    <path d="M91.5 338.25H88.9012L87.6234 336.334C87.555 336.231 87.4623 336.147 87.3535 336.089C87.2448 336.031 87.1233 336 87 336H81C80.8767 336 80.7552 336.031 80.6465 336.089C80.5377 336.147 80.445 336.231 80.3766 336.334L79.0978 338.25H76.5C75.9033 338.25 75.331 338.487 74.909 338.909C74.4871 339.331 74.25 339.903 74.25 340.5V351C74.25 351.597 74.4871 352.169 74.909 352.591C75.331 353.013 75.9033 353.25 76.5 353.25H91.5C92.0967 353.25 92.669 353.013 93.091 352.591C93.5129 352.169 93.75 351.597 93.75 351V340.5C93.75 339.903 93.5129 339.331 93.091 338.909C92.669 338.487 92.0967 338.25 91.5 338.25ZM87.375 345.375C87.375 346.043 87.1771 346.695 86.8062 347.25C86.4354 347.805 85.9083 348.238 85.2916 348.493C84.6749 348.749 83.9963 348.815 83.3416 348.685C82.6869 348.555 82.0855 348.233 81.6135 347.761C81.1415 347.289 80.8201 346.688 80.6898 346.033C80.5596 345.379 80.6265 344.7 80.8819 344.083C81.1374 343.467 81.5699 342.94 82.125 342.569C82.68 342.198 83.3325 342 84 342C84.8951 342 85.7535 342.356 86.3865 342.989C87.0194 343.621 87.375 344.48 87.375 345.375Z" fill="white" fill-opacity="0.5" />
                    <path d="M231.5 158.25H228.901L227.623 156.334C227.555 156.231 227.462 156.147 227.354 156.089C227.245 156.031 227.123 156 227 156H221C220.877 156 220.755 156.031 220.646 156.089C220.538 156.147 220.445 156.231 220.377 156.334L219.098 158.25H216.5C215.903 158.25 215.331 158.487 214.909 158.909C214.487 159.331 214.25 159.903 214.25 160.5V171C214.25 171.597 214.487 172.169 214.909 172.591C215.331 173.013 215.903 173.25 216.5 173.25H231.5C232.097 173.25 232.669 173.013 233.091 172.591C233.513 172.169 233.75 171.597 233.75 171V160.5C233.75 159.903 233.513 159.331 233.091 158.909C232.669 158.487 232.097 158.25 231.5 158.25ZM227.375 165.375C227.375 166.043 227.177 166.695 226.806 167.25C226.435 167.805 225.908 168.238 225.292 168.493C224.675 168.749 223.996 168.815 223.342 168.685C222.687 168.555 222.086 168.233 221.614 167.761C221.142 167.289 220.82 166.688 220.69 166.033C220.56 165.379 220.626 164.7 220.882 164.083C221.137 163.467 221.57 162.94 222.125 162.569C222.68 162.198 223.332 162 224 162C224.895 162 225.754 162.356 226.386 162.989C227.019 163.621 227.375 164.48 227.375 165.375Z" fill="white" fill-opacity="0.5" />
                    <path d="M231.5 338.25H228.901L227.623 336.334C227.555 336.231 227.462 336.147 227.354 336.089C227.245 336.031 227.123 336 227 336H221C220.877 336 220.755 336.031 220.646 336.089C220.538 336.147 220.445 336.231 220.377 336.334L219.098 338.25H216.5C215.903 338.25 215.331 338.487 214.909 338.909C214.487 339.331 214.25 339.903 214.25 340.5V351C214.25 351.597 214.487 352.169 214.909 352.591C215.331 353.013 215.903 353.25 216.5 353.25H231.5C232.097 353.25 232.669 353.013 233.091 352.591C233.513 352.169 233.75 351.597 233.75 351V340.5C233.75 339.903 233.513 339.331 233.091 338.909C232.669 338.487 232.097 338.25 231.5 338.25ZM227.375 345.375C227.375 346.043 227.177 346.695 226.806 347.25C226.435 347.805 225.908 348.238 225.292 348.493C224.675 348.749 223.996 348.815 223.342 348.685C222.687 348.555 222.086 348.233 221.614 347.761C221.142 347.289 220.82 346.688 220.69 346.033C220.56 345.379 220.626 344.7 220.882 344.083C221.137 343.467 221.57 342.94 222.125 342.569C222.68 342.198 223.332 342 224 342C224.895 342 225.754 342.356 226.386 342.989C227.019 343.621 227.375 344.48 227.375 345.375Z" fill="white" fill-opacity="0.5" />
                    <defs>
                        <filter id="filter0_d_577_1937" x="0" y="0" width="308" height="458" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_1937" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_1937" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <img width="130px" height="170px" src={props.imageSrc1} className='guestSelectPictureFrame2Image1Img'></img>
            <div className='guestSelectPictureFrame2Image1Div'>
                <label htmlFor="frame2Image1" className='guestSelectPictureFrame2Label'></label>
            </div>
            <input
                accept="image/*"
                type="file"
                onChange={(e) => props.onUpload(e, 1)}
                id="frame2Image1"
                style={{ display: "none" }}></input>
            <img width="130px" height="170px" src={props.imageSrc2} className='guestSelectPictureFrame2Image2Img'></img>
            <div className='guestSelectPictureFrame2Image2Div'>
                <label htmlFor="frame2Image2" className='guestSelectPictureFrame2Label'></label>
            </div>
            <input
                accept="image/*"
                type="file"
                onChange={(e) => props.onUpload(e, 2)}
                id="frame2Image2"
                style={{ display: "none" }}></input>
            <img width="130px" height="170px" src={props.imageSrc3} className='guestSelectPictureFrame2Image3Img'></img>
            <div className='guestSelectPictureFrame2Image3Div'>
                <label htmlFor="frame2Image3" className='guestSelectPictureFrame2Label'></label>
            </div>
            <input
                accept="image/*"
                type="file"
                onChange={(e) => props.onUpload(e, 3)}
                id="frame2Image3"
                style={{ display: "none" }}></input>
            <img width="130px" height="170px" src={props.imageSrc4} className='guestSelectPictureFrame2Image4Img'></img>
            <div className='guestSelectPictureFrame2Image4Div'>
                <label htmlFor="frame2Image4" className='guestSelectPictureFrame2Label'></label>
            </div>
            <input
                accept="image/*"
                type="file"
                onChange={(e) => props.onUpload(e, 4)}
                id="frame2Image4"
                style={{ display: "none" }}></input>
        </div>
    );
}

export default Frame2SelectPic;
