import '../css/GuestSelectFrameDesign.css';
import '../css/GuestSubmit.css';

function Frame1({ width, height, fill, isFill, image1, image2, image3, image4, from }) {

    return (
        <div style={{ position: "relative" }}>
            <div>
                <svg width={width} height={height} viewBox="0 0 88 254" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_703_1798)">
                        <rect x="4" y="6" width="80" height="240" rx="6" fill={fill} fill-opacity="0.9" shape-rendering="crispEdges" />
                    </g>
                    {!isFill ? <rect x="12" y="16" width="64" height="44" rx="4" fill="#333333" /> : null}
                    {!isFill ? <rect x="12" y="64" width="64" height="44" rx="4" fill="#333333" /> : null}
                    {!isFill ? <rect x="12" y="112" width="64" height="44" rx="4" fill="#333333" /> : null}
                    {!isFill ? <rect x="12" y="160" width="64" height="44" rx="4" fill="#333333" /> : null}
                    <rect x="20" width="48" height="12" rx="1" fill="white" fill-opacity="0.7" />
                    <defs>
                        <filter id="filter0_d_703_1798" x="0" y="6" width="88" height="248" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_703_1798" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_703_1798" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            {isFill
                ? from === "submit"
                    ? <div>
                        <img width="87px" height="60px" src={image1} className='guestSubmitFrame1Image1Img'></img>
                        <img width="87px" height="60px" src={image2} className='guestSubmitFrame1Image2Img'></img>
                        <img width="87px" height="60px" src={image3} className='guestSubmitFrame1Image3Img'></img>
                        <img width="87px" height="60px" src={image4} className='guestSubmitFrame1Image4Img'></img>
                    </div>
                    : <div>
                        <img width="64px" height="44px" src={image1} className='guestSelectFrame1Image1Img'></img>
                        <img width="64px" height="44px" src={image2} className='guestSelectFrame1Image2Img'></img>
                        <img width="64px" height="44px" src={image3} className='guestSelectFrame1Image3Img'></img>
                        <img width="64px" height="44px" src={image4} className='guestSelectFrame1Image4Img'></img>
                    </div>
                : null}

        </div>
    );
}

export default Frame1;