import '../css/GuestSelectPicture.css';
import '../css/GuestSelectFrame.css';
import Frame1SelectPic from '../icons/frame1_select_picture.js';
import Frame2SelectPic from '../icons/frame2_select_picture.js';
import Frame3SelectPic from '../icons/frame3_select_picture.js';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import LeftArrow from '../icons/left_arrow.js';
import RightArrow from '../icons/right_arrow.js';


function GuestSelectPicture() {

    const location = useLocation();
    const frameType = location.state.frameType;

    const navigator = useNavigate();

    const [imageSrc1, setImageSrc1] = useState(null);
    const [imageSrc2, setImageSrc2] = useState(null);
    const [imageSrc3, setImageSrc3] = useState(null);
    const [imageSrc4, setImageSrc4] = useState(null);

    const [imageFile1, setImageFile1] = useState(null);
    const [imageFile2, setImageFile2] = useState(null);
    const [imageFile3, setImageFile3] = useState(null);
    const [imageFile4, setImageFile4] = useState(null);

    const [isFinished, setIsFinished] = useState(false);

    const checkIsFinished = () => {
        if (frameType === 1) {
            if (imageSrc1 !== null
                && imageSrc2 !== null
                && imageSrc3 !== null
                && imageSrc4 !== null) {

                setIsFinished(true);
                return;
            }

        }

        if (frameType === 2) {
            if (imageSrc1 !== null
                && imageSrc2 !== null
                && imageSrc3 !== null
                && imageSrc4 !== null) {

                setIsFinished(true);
                return;
            }

        }

        if (frameType === 3) {
            if (imageSrc1 !== null) {
                setIsFinished(true);
                return;
            }
        }

        return;
    }

    const onUpload = async (e, idx) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        if(file === null) {
            return;
        }

        reader.readAsDataURL(file);

        return new Promise((resolve) => {
            reader.onload = () => {
                if (idx === 1) {
                    setImageFile1(file);
                    setImageSrc1(reader.result);
                    resolve(imageSrc1);
                } else if (idx === 2) {
                    setImageFile2(file);
                    setImageSrc2(reader.result);
                    resolve(imageSrc2);
                } else if (idx === 3) {
                    setImageFile3(file);
                    setImageSrc3(reader.result);
                    resolve(imageSrc3);
                } else if (idx === 4) {
                    setImageFile4(file);
                    setImageSrc4(reader.result);
                    resolve(imageSrc4);
                }
            };
        })
    }

    const goNext = () => {
        navigator({
            pathname: '/guestselectframedesign/',
        },
            {
                state: {
                    frameType: frameType,
                    image1: imageFile1,
                    image2: imageFile2,
                    image3: imageFile3,
                    image4: imageFile4,
                }
            })
    }

    const goBack = () => {
        navigator(-1);
    }

    return (
        <div className='guestSelectPicturePageWrapper'>
            <div className='guestSelectPicturePage'
                style={{ backgroundColor: "#FFE8E3" }}>
                <div className='guestSelectPictureHeader'
                    style={{ backgroundColor: "#F19292" }}>
                    <div className='guestSelectPictureTextWrapper'>
                        <div className='guestSelectPictureText'
                            style={{ color: "#FFFFFF" }}>
                            각 프레임을 눌러 사진을 선택해 주세요.
                        </div>
                    </div>
                    <div className='guestSelectPictureVerticalSeparateLine'></div>
                    <div className='guestSettingProgressWrapper'>
                        <div className='guestSettingProgress'
                            style={{ color: "#FFFFFF" }}>
                            2/3
                        </div>
                    </div>
                    <div className='guestSelectPictureHorizontalSeparateLine'></div>
                </div>
                <div className='guestSelectPictureBody'>
                    {frameType === 1
                        ? <Frame1SelectPic
                            onUpload={onUpload}
                            setIsFinished={setIsFinished}
                            imageSrc1={imageSrc1}
                            imageSrc2={imageSrc2}
                            imageSrc3={imageSrc3}
                            imageSrc4={imageSrc4}></Frame1SelectPic>
                        : frameType === 2
                            ? <Frame2SelectPic
                                onUpload={onUpload}
                                setIsFinished={setIsFinished}
                                imageSrc1={imageSrc1}
                                imageSrc2={imageSrc2}
                                imageSrc3={imageSrc3}
                                imageSrc4={imageSrc4}></Frame2SelectPic>
                            : <Frame3SelectPic
                                onUpload={onUpload}
                                setIsFinished={setIsFinished}
                                imageSrc1={imageSrc1}></Frame3SelectPic>}
                </div>
                <div className='guestSelectPictureFooter'>
                    <div className='guestSelectPictureHorizontalSeparateLine'></div>
                    <div className='guestSelectPictureButtonsWrapper'>
                        <div className='guestSelectPictureBackWrapper'
                            onClick={goBack}
                            style={{ backgroundColor: "#F19292" }}>
                            <LeftArrow className='guestSelectPictureBackLeftArrowIcon'
                                fill={"#FFFFFF"}></LeftArrow>
                            <div className='guestSelectPictureBackText'
                                style={{ color: "#FFFFFF" }}>
                                뒤로
                            </div>
                        </div>
                        <div className='guestSelectPictureVerticalSeparateLine'></div>
                        <div className='guestSelectPictureNextWrapper'
                            onClick={() => {
                                checkIsFinished();
                                if(isFinished) {
                                    goNext();
                                } 

                                return;
                            }}
                            style={{ backgroundColor: isFinished ? "#F19292" : '#808080' }}>
                            <div className='guestSelectPictureNextText'
                                style={{ color: "#FFFFFF" }}>
                                다음
                            </div>
                            <div className='guestSelectPictureNextRightArrowIcon'>
                                <RightArrow fill={"#FFFFFF"}></RightArrow>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuestSelectPicture;